import React, { createContext, ReactNode, useEffect, useCallback } from "react";
import {
  CreateLocalTrackOptions,
  ConnectOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
  TwilioError,
  Logger,
} from "twilio-video";

import { ErrorCallback } from "../../types";
import { SelectedParticipantProvider } from "./useSelectedParticipant/useSelectedParticipant";
import { firebase, useApi } from "components";

import AttachVisibilityHandler from "./AttachVisibilityHandler/AttachVisibilityHandler";
import useHandleRoomDisconnection from "./useHandleRoomDisconnection/useHandleRoomDisconnection";
import useHandleTrackPublicationFailed from "./useHandleTrackPublicationFailed/useHandleTrackPublicationFailed";
import useRestartAudioTrackOnDeviceChange from "./useRestartAudioTrackOnDeviceChange/useRestartAudioTrackOnDeviceChange";
import useLocalTracks from "./useLocalTracks/useLocalTracks";
import useRoom from "./useRoom/useRoom";
import useScreenShareToggle from "./useScreenShareToggle/useScreenShareToggle";
const logg = Logger.getLogger("twilio-video");
logg.setLevel("DEBUG");
/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext {
  room: Room | null;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  connect: (token: string, options: any, muted: boolean) => Promise<void>;
  joinRoom: (roomName: string, muted?: boolean) => Promise<null | undefined>;
  onError: ErrorCallback;
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions
  ) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  isAcquiringLocalTracks: boolean;
  removeLocalAudioTrack: () => void;
  removeLocalVideoTrack: () => void;
  isSharingScreen: boolean;
  toggleScreenShare: () => void;
  getAudioAndVideoTracks: () => Promise<void>;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError: ErrorCallback;
  children: ReactNode;
}

export function VideoProvider({
  options,
  children,
  onError = () => {},
}: VideoProviderProps) {
  const onErrorCallback: ErrorCallback = useCallback(
    (error) => {
      console.log(`ERROR: ${error.message}`, error);
      onError(error);
    },
    [onError]
  );

  const { getRoomToken } = useApi();

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();
  const { room, isConnecting, connect } = useRoom(
    localTracks,
    onErrorCallback,
    options
  );

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(
    room,
    onError
  );

  useHandleTrackPublicationFailed(room, onError);
  useRestartAudioTrackOnDeviceChange(localTracks);

  const joinRoom = async (roomName: string, muted: boolean = false) => {
    console.log("joinRoom", roomName, muted);
    if (!roomName || roomName === "") return;
    try {
      //@ts-ignore
      const tokenName = firebase.auth().currentUser.uid;
      const { data } = await getRoomToken(tokenName, roomName);
      // connect to room and return room Sid and participant Sid
      const r = await connect(
        data.token,
        {
          name: roomName,
          recordParticipantsOnConnect: true, //roomName.indexOf('moderator') > -1 ? false : true,
          networkQuality: {
            local: 1,
            remote: 1,
          },
        },
        muted
      );
    } catch (err) {
      console.log("!AFTER - ERROR", err.message);
      console.error("Error getting room token", err.message);
    }
    return null;
  };

  return (
    <VideoContext.Provider
      value={{
        room,
        joinRoom,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isAcquiringLocalTracks,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
        isSharingScreen,
        toggleScreenShare,
        getAudioAndVideoTracks,
      }}
    >
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
      {/* 
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}
